import React from "react"
import styled from "styled-components"
import { Text } from "@atoms"

const QuoteBlockTrustCosmicWhite = ({ quoteData, ...props }) => {
  const { quote, author } = quoteData
  const logo = author?.company?.logo?.file?.url
  const logoCheck = author?.company?.logo
  const logoAlt = author?.company?.logo?.title

  return (
    <CompWrapper {...props} data-comp={QuoteBlockTrustCosmicWhite.displayName}>
      <QuoteWrapper>
        {logoCheck !== undefined ? (
          <img src={logo} alt={logoAlt || "logo"} />
        ) : (
          ""
        )}
        <TextWrapper>
          <Text
            style={{ fontSize: "28px", lineHeight: "34px", color: "#FFFFFF" }}
          >
            {quote?.quote && quote?.quote}
          </Text>
          {author && (
            <AuthorDetailsFlex>
              {author?.fullName && (
                <Text
                  style={{
                    marginTop: "1px",
                    fontSize: "16px",
                    display: "inline",
                    color: "#FFFFFF",
                  }}
                >
                  <span style={{ fontWeight: 700 }}>{author?.fullName}</span>
                  <span style={{ fontWeight: 400 }}>
                    {author?.role && `, ${author?.role}`}
                    {author?.company?.name && `, ${author?.company?.name}`}
                  </span>
                </Text>
              )}
            </AuthorDetailsFlex>
          )}
        </TextWrapper>
      </QuoteWrapper>
    </CompWrapper>
  )
}

QuoteBlockTrustCosmicWhite.displayName = "QuoteBlockTrustCosmicWhite"
export default QuoteBlockTrustCosmicWhite

const CompWrapper = styled.div`
  background: #090069;
  width: 100%;
  padding: 50px 50px 50px 50px;
  img {
    padding-bottom: 25px;
    filter: brightness(0) invert(1);
  }
`

const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
`

const TextWrapper = styled.div`
  display: inline-block;
`

const AuthorDetailsFlex = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
`
